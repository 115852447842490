import React, {useState} from 'react';

import { useLocation } from "react-router-dom";


export const Download = () => {
  const location = useLocation();
  //define all constant variables required throughout
  const urlstate = location.state.params;
  const Bucket = process.env.REACT_APP_BUCKET_NAME
  const S3_region = process.env.REACT_APP_S3_REGION
 
  const AWS = require('aws-sdk'); 
  
  let presignedGETURL;
  
//testing comment to trigger CICD for amplify 
 //function to generate a presigned URL to retrieve the object at the prefix specified
  async function generatePresignedUrl(keyFile) {
    try {
      let bucketParams = {
        Bucket: Bucket, // your bucket name,
        Key: keyFile, // path to the object you're looking for
        Expires: 60 * 5
      }
      //const s3 = await new AWS.S3();
      const s3 = await new AWS.S3({region: S3_region});
    
      presignedGETURL = await s3.getSignedUrl('getObject', bucketParams);

      return presignedGETURL;
  
    } catch (err) {
      console.log("error call during call s3 ".concat(err))
      throw err;
    }
  }

  //this function combines the head method (to check if the file specified exists) with the get url method, to catch errors arising from trying to download a file that does not exist 
  async function main(keyFile) {
    try {
      const params = {
        Bucket: Bucket, 
        Key: keyFile
      };
      //const s3 = new AWS.S3();
      const s3 = await new AWS.S3({region: S3_region});
      
     
      await s3.headObject(params, async function(err, data) {
        if (err)
        {
          alert("The requested resource " + keyFile + " could not be found"); // an error occurred
          console.log(err)
          window.location.reload();
        }
        else {
          presignedGETURL = await generatePresignedUrl(keyFile);
         const signedurl = await presignedGETURL;
         console.log(presignedGETURL)
         console.log(data)
        window.open(signedurl, "_self");
       
          
        } 
      });
    } 
    catch (err) {
  
      alert("The requested resource could not be found", err);
      window.location.reload();
    }
  }
  main(urlstate);
  return <p>"Your file is being Downloaded.."</p>;
  
  
};
