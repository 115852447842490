import { Auth0Lock } from 'auth0-lock';
import React from 'react';

import { useHistory } from 'react-router-dom'



export const LoginFunction = () => {
  
//test comment1 for cicd
  //define all constant variables required throughout
  const auth0Client = process.env.REACT_APP_AUTH_0_CLIENT
  const auth0Domain = process.env.REACT_APP_AUTH_0_DOMAIN
  const region = process.env.REACT_APP_REGION
  const identityPool = process.env.REACT_APP_IDENTITY_POOL
  const role = process.env.REACT_APP_ROLE_ARN
  
  const AWS = require('aws-sdk'); 
  
  let history = useHistory()
  
// Get the s3 path from the current URL
  const lpathname = window.location.pathname
    let ls3Key;
    
    ls3Key = (lpathname.split('%20').join(' ')).substring(1);
    if(ls3Key.charAt( ls3Key.length-1 ) === "/") {
      
      ls3Key = ls3Key.slice(0, -1)
      
      
  }
  

  //specify options to be passed to the auth0 lock
  var options = {

    avatar: null,
    
   closable: true,

    autoclose: true,

    languageDictionary: {
      title: "S3 Offline Access"
    },

    theme: {
      logo: "https://vvp.vc/wp-content/uploads/2017/02/Winmore-logo.png"
    },

    auth: {
      redirectUrl: window.location.origin,
      
      redirect: false,
      
  
      responseType: 'token id_token',
      params: {
        scope: 'openid'
      }
    }
  };

  //create new auth0 lock object and create sign in function
  var lock = new Auth0Lock(`${auth0Client}`, `${auth0Domain}`, options);
  
  function signin() {
    lock.show();
    //Remove the lock object created by auth0 that causes react router not to direct to download page
   // var auth_container_obj = document.getElementById("auth0-lock-container-1");
     // auth_container_obj.remove();
    let container = document.getElementById("auth0-lock-container-1")
if(container.hasChildNodes()){
container.removeChild(container.firstChild);
}
      }

  //actions to perform when a user is confirmed to have been authenticated
  
  lock.on("authenticated", async function(authResult) {
   
   
    AWS.config.region=region
 
    //update a user's credentials to give them permission to access the s3 objects
    AWS.config.credentials = await new AWS.CognitoIdentityCredentials({ 
      IdentityPoolId: `${identityPool}`,
      Logins: {
        'lanetix.auth0.com' : authResult.idToken
      },
      RoleArn: `${role}`
    });
    
// Authentication/Authorization complete , now direct User to a seperate page for download
      history.push('/download',{params:ls3Key})
    
  });


 
  signin();
  
 

  
   
  return null;
  
  

}
